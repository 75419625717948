<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-import">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Bonos por Excel</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="form-group col-md-12 rounded mb-0 pb-3"
              :class="
                form.sitio_id && form.file 
                  ? 'alert-default-success border border-success'
                  : 'alert-default-warning border border-warning'
              "
            >
              <label class="mt-2 font-weight-bold text-md" for="origen">Sitio</label>
              <div class="form-group">
                <v-select
                  class="form-control form-control-sm p-0"
                  v-model="sitio"
                  placeholder="Sitio"
                  label="nombre"
                  :options="listasForms.sitios"
                  :filterable="false"
                  @search="buscarSitio"
                  @input="selectSitio"
                  :class="[
                    $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid shadow',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                ></v-select>
              </div>
              <input
                type="file"
                id="file"
                ref="file"
                class="form-control-file mt-2"
                v-on:change="elegirDirectorio()"
                accept=".XLSX, .CSV"
                :class="
                  $v.form.file.$invalid
                    ? 'btn btn-sm border border-warning alert-warning'
                    : 'btn btn-sm border border-success bg-gradient-success shadow'
                "
              />
            </div>
          </div>
          <div class="card-body" v-if="progress > 0">
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                :class="
                  progress > 0 && progress < 46
                    ? 'text-light bg-danger'
                    : progress > 45 && progress < 95
                    ? 'bg-warning'
                    : progress > 94
                    ? 'bg-success'
                    : ''
                "
                role="progressbar"
                :style="{ width: progress + '%' }"
              >
                <b class="text-white"> {{ progress }} % </b>
              </div>
            </div>
          </div>
          <div class="modal-footer p-1">
            <div class="row">
              <button
                type="button"
                class="btn bg-gradient-primary shadow"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
              <button
                type="button"
                class="btn bg-gradient-dark shadow"
                @click="exportExcel()"
                v-show="$v.form.$invalid"
              >
                Plantilla
                <i class="fas fa-cloud-download-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "BonoImportExcel",
  components: {
    vSelect,
  },
  data() {
    return {
      form: { sitio_id: null, file: null },
      sitio: [],
      listasForms: {
        sitios: [],
      },
      progress: 0,
    };
  },
  validations: {
    form: {
      sitio_id: {
        required,
      },
      file: {
        required,
      },
    },
  },
  methods: {
    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    buscarSitio(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            me.form.sitio_id = me.listasForms.sitios.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.form.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    importExcel() {
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("sitio_id", this.form.sitio_id);
      axios
        .post("/api/hidrocarburos/almacenBonos/importExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response) => {
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          this.form.file = null;
          this.form.sitio_id = null;
          this.sitio = [];
          this.progress = this.progress == 100 ? 0 : this.progress;
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>Los datos del archivo no se cargaron.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>Los datos del bono fuerón cargados exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }
          this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            //width: "800px",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.file = null;
              this.visible = 1;
            }
          });
        })
        .catch((e) => {
          this.progress = this.progress == 100 ? 0 : this.progress;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    exportExcel() {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/almacenBonos/exportExcel",
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    limpiarModal() {
      this.form = { sitio_id: null, file: null };
      this.sitio = [];
    },
  },
};
</script>
